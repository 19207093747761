import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"

class WorldCups extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiIntStats.edges
    const rdata = this.props.data.allSheetRonaldoIntStats.edges

    const seasons = rdata.map(d => d.node.year).reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    return (
      <Layout>
        <SEO
          title={"World Cup Stats - Messi vs Ronaldo Goals and Stats in World Cups"}
          description={`Lionel Messi and Cristiano Ronaldo are two of the greatest players ever, but how have they performed in football's most prestigious competition, the World Cup?`}
          canonicalPath={`/international-stats/world-cup-stats/`}
        />

        <h1>
          <span class="sr-only">Messi and Ronaldo</span> World Cup Stats
        </h1>

      
        <CompetitionBlock competition="World Cup" compTitle="All World Cups" type="int" modalSubTitle={`All Time`} mdata={this.props.data.allSheetMessiAllTimeStats.edges} rdata={this.props.data.allSheetRonaldoAllTimeStats.edges} />

        <CompetitionBlock competition="World Cup Knockouts" type="int" modalSubTitle={`All World Cup KOs`} mdata={this.props.data.allSheetMessiAllTimeStats.edges} rdata={this.props.data.allSheetRonaldoAllTimeStats.edges} />

        { seasons.map(season => (
            <CompetitionBlock key={season} competition="World Cup" compTitle={`${season} World Cup`} type="int" modalSubTitle={`${season} World Cup`} mdata={mdata.filter(d => d.node.year === season )} rdata={rdata.filter(d => d.node.year === season )} />
          ))
        }

        <LastUpdated type="Stats" />
          
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetMessiIntStats(filter: {pgKey: {regex: "/world-cup/"}}) {
      edges {
        node {
          id
          competition
          year
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          fkc
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/world-cup/"}}) {
      edges {
        node {
          id
          competition
          year
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          fkc
        }
      }
    }
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/world-cup/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          fkc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/world-cup/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          fkc
        }
      }
    }
  }
`

export default WorldCups
